.time-container {
  width: 489px;
  // height: 199px;
  background: rgba(61, 61, 61, 0.3);
  border-radius: 10px;

  .rebase-text {
    margin: 20px 0 30px;
    font-size: 18px;
    font-family: Kano;
    font-weight: bold;
    color: #FFFFFF;
  }
  .countItem-text {
    padding: 17px 9px;
    background: #3D3D3D;
    opacity: 0.5;
    border-radius: 10px;
    font-size: 30px;
    font-family: Seravek;
    font-weight: bold;
    color: #FFFFFF;
  }
  .time-item {
    font-size: 12px;
    font-family: Kano;
    font-weight: 400;
    color: #8A8A8A;
    line-height: 35px;
  }
}
