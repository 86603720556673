.calculator-view {
	padding-top: 20px;
	padding-left: 50px;
	padding-right: 200px;

	.stake-head {
		width: 100%;
	}

	.stake-top {
		width: 100%;
		padding: 50px 100px 0 120px;

		.top-container {
			padding-bottom: 30px;
			border-bottom: 1px solid #727272;

			.top-item {
				align-items: center;

				.top-label {
					margin-bottom: 21px;
					font-size: 18px;
					font-family: Kano;
					font-weight: 400;
					color: #8A8A8A;
				}

				.top-value {
					font-size: 30px;
					font-family: Seravek;
					font-weight: bold;
					color: #FFFFFF;
				}
			}
		}
	}
	
	.stake-mid-app {
		display: none;
	}

	.stake-mid {
		width: 100%;
		padding: 8px 0 20px 48px;

		.mid-container {
			padding-bottom: 30px;
			
			.mid-item {
				align-items: center;
				max-width: 170px;

				.mid-label {
					margin-bottom: 10px;
					text-align: center;
					font-size: 16px;
					line-height: 35px;
					font-family: Kano;
					font-weight: 400;
					color: #8A8A8A;
				}

				.mid-value {
					font-size: 18px;
					font-family: Seravek;
					font-weight: bold;
					color: #FFFFFF;
				}
			}
			.lambos-number {
				max-width: 124px;
			}
		}
	}

	.stake-input {
		flex-wrap: wrap;
		padding-left: 50px;
		
		.input-item {
			width: 40%;
			margin-bottom: 40px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.snowglobe-input {
				width: 292px;
				height: 50px;
			}
		}

		.input-label {
			font-size: 16px;
			font-family: Kano;
			font-weight: bold;
			color: #FFFFFF;
			margin-bottom: 18px;
		}

		.days-item {
			padding: 0 50px 20px 38px;
		
			.snowglobe-days-input {
				width: 100%;
			}
		}
}
}
