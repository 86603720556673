@media only screen and (max-width: 500px) {
	.ido-info-view {
		padding: 0;

		.ido-top {
			display: flex;
			justify-content: center;
			border: none;

			.ido-top-content {
				width: 90%;
				border-bottom: 2px solid #3D3D3D;
				flex-wrap: wrap;
				justify-content: space-between;

				.ido-top-content-left {
					display: flex;
					justify-content: flex-end;
					flex-direction: column;
					padding-top: 30px;
	
					.white-not {
						flex-direction: column;
						align-items: flex-start;
						font-size: 12px;

						.whitelist {
							margin-bottom: 20px;
						}

						img {
							width: 15px;
							height: 15px;
							margin-right: 14px;
						}
					}

				}

				.ido-top-content-right {
					justify-content: flex-end;

					.top-label {
						font-size: 12px;
						margin-bottom: 12px;
					}

					.top-value {
						font-size: 24px;
					}

					p {
						margin-bottom: 0;
					}
				}
			}
		}

		.ido-bot {
			justify-content: center;
			padding-right: 0;

			.ido-bot-left {
	
				.bot-left-top {
	
					.ido-usdt {
						width: 128px;
						height: 76px;
						padding-top: 24px;
					}
					.ido-mod {
						padding-top: 24px;
						width: 128px;
						height: 76px;
					}

					.ido-label {
						font-size: 20px;
						line-height: 16px;
						margin-bottom: 15px;
					}
	
					.ido-value {
						font-size: 12px;
					}

					.swap-icon {
						width: 14px;
						height: 14px;
					}
				}

				.bot-left-mid {
					margin: 40px 0 32px;
				}

				.bot-left-bot {
					margin-bottom: 32px;
				}
			}

			.ido-bot-right {
			
				.bot-right-item {
					width: 319px;
					height: 43px;
					padding: 14px 16px;
				}
			}
		}
	}
}