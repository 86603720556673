@media only screen and (max-width: 500px) {
  .stake-view {

    .stake-header {
      margin-bottom: 21px;
      font-size: 30px;
    }

    .stake-view-container {
      width: 100%;
      padding: 26px 6px;
    }

    .stake-data-container {
      flex-direction: column-reverse;
      flex-wrap: wrap;
      margin-bottom: 24px;

      .stake-data {
        width: 100%;
        height: 204px;
        padding-left: 19px;
        padding-top: 4px;
        padding-bottom: 8px;
        flex-wrap: wrap;
        background: rgba(61, 61, 61, 0.3);
        border-radius: 10px;

        .stake-data-item {
          width: 50%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .item-label {
            font-size: 12px;
            line-height: 35px;
          }
        }
      }
    }

    // .stake-top-metrics {
    //   display: flex;
    //   width: 100%;
    //   padding: 0;
    //   text-align: center;
    //   margin-top: 10px;
    //   h5 {
    //     font-weight: 400;
    //   }
    //   h4 {
    //     font-weight: 500;
    //     margin-top: 17px;
    //   }
    //   .stake-apy,
    //   .stake-tvl,
    //   .stake-index {
    //     flex: 1;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     text-overflow: ellipsis;
    //   }
    // }
    
    .staking-area {
      margin: 38px 0 13px 0;
    }

    
    .stake-amount-input {
      width: 330px;
      margin-right: 0 !important;
      margin-bottom: 23px;

      .amount-input {
        height: 71px;
      }
      #amount-input {
        height: 22px;
        font-size: 30px;
        font-family: Seravek;
        font-weight: bold;
        color: #FFFFFF;
      }
    }

    
    .stake-tab-panel,
    .stake-tab-panel > div.MuiBox-root {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
    }

    .stake-note {
      width: 95%;
    }
  }
}





#stake-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .rebase-timer {
    margin: 0 !important;
    line-height: 110% !important;
  }

  .stake-tab-buttons {
    margin-bottom: 0.25rem;
  }

  .stake-table-panel,
  .stake-tab-panel > div.MuiBox-root {
    width: 100%;
    padding: 0;
  }

  .ohm-card .header {
    min-height: 3em;
  }
  .ohm-card.secondary .card-header {
    min-height: 33px !important;
    height: 20px;
  }
}

.border-right {
  border-right: 2px solid #3D3D3D;
}

.MuiOutlinedInput-root {
  .MuiButton-label {
    color: #BBB;
  }
}

.migrate-sohm-button {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 1em;
  display: flex;
  align-items: center;
  color: #f08080;
  line-height: 30px;
  svg {
    margin-right: 8px;
    color: inherit;
  }
}

.migrate-sohm-button.complete {
  color: #35ee66 !important;
}

.ohm-logo-tiny {
  height: 14px;
  width: 14px;
  vertical-align: middle;
  background-color: transparent;
  align-self: center;
  justify-self: center;
}

.logo-holder {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  background: none;
  border: 0 !important;
  border-radius: inherit;
  color: #555;
}

.stake-action-area {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;
}

.stake-action-row {
  margin-top: 40px;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  padding-bottom: 30px;
}

.mobile .stake-action-row {
  justify-content: center;
}

.stake-action-row [role="tabpanel"] {
  width: 99%;
  min-width: 162px;
  max-width: 220px;
  margin: 5px;
}

.mobile .stake-action-row {
  flex-direction: column;
  [role="tabpanel"] {
    max-width: 100%;
    width: 100% !important;
  }
}

// .ohm-input {
//   max-width: 542px;
//   width: 100%;
//   margin: 5px !important;
// }

.stake-button {
  align-self: center;
  width: 205px !important;
  max-width: 205px !important;
  height: 72px !important;
  max-height: 72px !important;
}
.claim-button{
  span{
    font-size: 12px;
  }
  height: 20px !important;
}
.mobile .stake-button {
  width: 330px !important;
  min-width: 330px;
  align-self: center;
  justify-self: center;
}

.stake-lp-button {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  svg {
    margin-left: 4px;
    vertical-align: middle;
    font-size: 1.1em;
  }
  &:hover {
    svg {
      color: inherit;
    }
  }
}

.stake-user-data {
  justify-content: center;
  margin: auto;
  padding: 0 5px;
}

.tablet .stake-pool,
.mobile .stake-pool {
  .pool-card-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .ohm-pairs p {
      margin-bottom: 0 !important;
    }
  }

  .pool-data {
    margin-top: 15px;
    width: 100%;
    .pool-data-row {
      display: flex;
      justify-content: space-between;
      line-height: 225%;
    }
  }
  .stake-lp-button {
    margin-top: 10px;
    svg {
      margin-left: 10px !important;
    }
  }
}

.stake-wallet-notification {
  text-align: center;
  .wallet-menu {
    text-align: center;
    .connect-button {
      max-width: 300px !important;
      min-width: 240px !important;
      margin-bottom: 15px;
    }
  }
}
