.stake-view {

  .stake-header {
    margin-bottom: 26px;
    font-size: 36px;
    font-family: Kano;
    font-weight: bold;
    color: #FFFFFF;
  }

  .stake-view-container {
    width: 100%;
    padding: 16px 36px;
  }

  .stake-data-container {
    margin-bottom: 24px;

    .stake-data {
      width: 489px;
      height: 199px;
      padding-left: 18px;
      padding-top: 4px;
      padding-bottom: 8px;
      flex-wrap: wrap;
      background: rgba(61, 61, 61, 0.3);
      border-radius: 10px;

      .stake-data-item {
        width: 50%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .item-label {
          font-size: 14px;
          font-family: Kano;
          font-weight: 400;
          color: #8A8A8A;
          line-height: 35px;
        }

        .item-value {
          font-size: 24px;
          line-height: 17px;
          font-family: Seravek;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }
  }

  
  .stake-top-metrics {
    display: flex;
    width: 100%;
    padding: 0;
    text-align: center;
    margin-top: 10px;
    h5 {
      font-weight: 400;
    }
    h4 {
      font-weight: 500;
      margin-top: 17px;
    }
    .stake-apy,
    .stake-tvl,
    .stake-index {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-overflow: ellipsis;
      // white-space: nowrap;
      // overflow: hidden;
    }
  }

  
  .stake-amount-input {
    width: 620px;
    margin-right: 24px !important;

    .amount-input {
      height: 71px;
    }
    #amount-input {
      height: 22px;
      font-size: 30px;
      font-family: Seravek;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
  
  .stake-note {
    width: 80%;
    text-align: center;
    font-style: italic;
  }
}






#stake-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .rebase-timer {
    margin: 0 !important;
    line-height: 110% !important;
  }

  .stake-tab-buttons {
    margin-bottom: 0.25rem;
  }

  .stake-table-panel,
  .stake-tab-panel > div.MuiBox-root {
    width: 100%;
    padding: 0;
  }
  .mobile .stake-tab-panel,
  .mobile .stake-tab-panel > div.MuiBox-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  }

  .ohm-card .header {
    min-height: 3em;
  }
  .ohm-card.secondary .card-header {
    min-height: 33px !important;
    height: 20px;
  }

  .MuiTab-root {
    width: auto !important;
  }
}

.border-right {
  border-right: 2px solid #3D3D3D;
}

.MuiOutlinedInput-root {
  .MuiButton-label {
    color: #BBB;
  }
}

.migrate-sohm-button {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 1em;
  display: flex;
  align-items: center;
  color: #f08080;
  line-height: 30px;
  svg {
    margin-right: 8px;
    color: inherit;
  }
}

.migrate-sohm-button.complete {
  color: #35ee66 !important;
}

.mobile .stake-top-metrics {
  .stake-apy,
  .stake-tvl,
  .stake-index {
    align-items: center;
  }
}

.ohm-logo-tiny {
  height: 14px;
  width: 14px;
  vertical-align: middle;
  background-color: transparent;
  align-self: center;
  justify-self: center;
}

.logo-holder {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  background: none;
  border: 0 !important;
  border-radius: inherit;
  color: #555;
}

.stake-action-area {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;
}

.stake-action-row {
  margin-top: 40px;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  padding-bottom: 30px;
}

.mobile .stake-action-row {
  justify-content: center;
}

.stake-action-row [role="tabpanel"] {
  width: 99%;
  min-width: 162px;
  max-width: 220px;
  margin: 5px;
}

.mobile .stake-action-row {
  flex-direction: column;
  [role="tabpanel"] {
    max-width: 100%;
    width: 100% !important;
  }
}

// .ohm-input {
//   max-width: 542px;
//   width: 100%;
//   margin: 5px !important;
// }

.stake-button {
  align-self: center;
  width: 205px !important;
  max-width: 205px !important;
  height: 72px !important;
  max-height: 72px !important;
}
.claim-button{
  span{
    font-size: 12px;
  }
  height: 20px !important;
}
.mobile .stake-button {
  width: 100% !important;
  max-width: 542px;
  align-self: center;
  justify-self: center;
}

.staking-area {
  margin: 38px 10px 13px 10px;
}

.stake-lp-button {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  svg {
    margin-left: 4px;
    vertical-align: middle;
    font-size: 1.1em;
  }
  &:hover {
    svg {
      color: inherit;
    }
  }
}

.stake-user-data {
  justify-content: center;
  margin: auto;
  padding: 0 5px;
}

.tablet .stake-pool,
.mobile .stake-pool {
  .pool-card-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .ohm-pairs p {
      margin-bottom: 0 !important;
    }
  }

  .pool-data {
    margin-top: 15px;
    width: 100%;
    .pool-data-row {
      display: flex;
      justify-content: space-between;
      line-height: 225%;
    }
  }
  .stake-lp-button {
    margin-top: 10px;
    svg {
      margin-left: 10px !important;
    }
  }
}

.stake-wallet-notification {
  text-align: center;
  .wallet-menu {
    text-align: center;
    .connect-button {
      max-width: 300px !important;
      min-width: 240px !important;
      margin-bottom: 15px;
    }
  }
}
