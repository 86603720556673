@media only screen and (max-width: 500px) {
  .calculator-view {
    padding: 16px;
    padding-top: 60px;

    .stake-head {

      .head-text {
        font-size: 36px;
      }
    }
  
    .stake-top {
      width: 100%;
      padding: 0;
      padding-top: 15px;
      margin-top: 14px;
      margin-bottom: 25px;
      background: rgba(61, 61, 61, 0.5);
      border-radius: 10px;
  
      .top-container {
        padding-bottom: 0;
        border: none;
        flex-wrap: wrap;
  
        .top-item {
          width: 100%;
          margin-bottom: 18px;
          align-items: center;
  
          .top-label {
            margin-bottom: 13px;
            font-size: 12px;
          }
  
          .top-value {
            font-size: 24px;
          }
        }
      }
    }
  
    .stake-mid {
      display: none;
    }

    .stake-mid-app {
      width: 100%;
      margin-top: 45px;
      display: block;
      padding: 10px 10px 20px 20px;
      background: rgba(61, 61, 61, 0.5);;
  
      .mid-container {
        
        .mid-item {
          align-items: flex-start;
          max-width: 140px;
  
          .mid-label {
            margin-bottom: 0px;
            text-align: left;
            font-size: 12px;
            line-height: 35px;
            font-family: Kano;
            font-weight: 400;
            color: #8A8A8A;
          }
  
          .mid-value {
            font-family: Seravek;
            font-weight: bold;
            color: #FFFFFF;
            font-size: 24px;
          }
        }
      }
    }
  
    .stake-input {
      padding-left: 0;
      
      .input-item {
        width: auto;
        margin-bottom: 12px;
        align-items: center;

        .input-label {
          margin-bottom: 12px;
        }
      }

      .days-item {
        padding: 0 22px 20px;
      
        .snowglobe-days-input {
          width: 100%;
        }
      }
    }
  }
}
