.ido-info-view {
	padding: 74px 30px 30px 73px;

	.ido-top {
		width: 100%;
		border-bottom: 2px solid #3D3D3D;

		.ido-top-content {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding-bottom: 30px;

			.ido-top-content-left {
				display: flex;
				justify-content: flex-end;
				flex-direction: column;
				padding-top: 30px;

				.white-not {
					align-items: center;
					font-size: 18px;
					font-family: Kano;
					font-weight: 400;
					color: #FFFFFF;

					.whitelist {
						margin-right: 45px;
					}
					
					img {
						width: 22px;
						height: 22px;
						margin-right: 22px;
					}
				}
				p {
					font-size: 48px;
					font-family: Kano;
					font-weight: bold;
					color: #FFFFFF;
				}
			}

			.ido-top-content-right {
				display: flex;
				justify-content: flex-start;
				flex-direction: column;
				text-align: right;

				.top-label {
					font-size: 18px;
					font-family: Kano;
					font-weight: 400;
					color: #8A8A8A;
				}
				.top-value {
					font-size: 36px;
					font-family: Seravek;
					font-weight: bold;
					color: #FFFFFF;
				}
				p {
					font-size: 48px;
					font-family: Kano;
					font-weight: bold;
					color: #FFFFFF;
				}
			}
		}
	}

	.ido-bot {
		width: 100%;
		padding-top: 40px;
		padding-right: 40px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.ido-bot-left {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			align-items: center;

			.bot-left-top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.ido-usdt {
					width: 180px;
					height: 106px;
					padding: 32px 0 21px 20px;
					color: #FFF;
					position: relative;
					background-color: #138D69;
					border-radius: 10px;

					img {
						width: 20px;
						height: 20px;
						position: absolute;
						top: 9px;
						right: 16px;
					}
				}

				.ido-mod {
					width: 180px;
					height: 106px;
					padding: 32px 0 20px 20px;
					position: relative;
					color: #000;
					background-color: #E3C990;
					border-radius: 10px;

					img {
						width: 20px;
						height: 20px;
						position: absolute;
						top: 9px;
						right: 16px;
					}
				}

				.ido-label {
					font-size: 28px;
					line-height: 24px;
					font-family: Kano;
					font-weight: bold;
					margin-bottom: 20px;
				}

				.ido-value {
					font-size: 14px;
					line-height: 12px;
					font-family: Adobe Heiti Std;
					font-weight: normal;
				}

				.swap-icon {
					width: 20px;
					height: 20px;
					margin: 0 20px;
				}
			}

			.bot-left-mid {
				.left-mid-usdt {
					padding-bottom: 7px;
					margin-bottom: 13px;
					font-size: 36px;
					font-family: Seravek;
					font-weight: bold;
					color: #FFFFFF;
					border-bottom: 2px solid #3D3D3D;
				}

				.left-mid-mod {
					font-size: 18px;
					font-family: Seravek;
					font-weight: bold;
					color: #FFFFFF;
				}
			}

			.bot-left-bot {
				display: flex;
				align-items: center;

				.left-bot-btn {
					width: 180px;
					height: 40px;
					border-radius: 20px;
					background-color: transparent;
					border: 1px solid #E3C990;

					font-size: 18px;
					font-family: Seravek;
					font-weight: bold;
					color: #FFF;
					cursor: pointer;

					&:hover {
						background-color: #E3C990;
						color: #030303;
					}
				}
			}
		}

		.ido-bot-right {
			
			.bot-right-item {
				width: 436px;
				height: 43px;
				padding: 15px 28px;
				margin-bottom: 16px;
				background: #3D3D3D;
				border-radius: 10px;

				font-size: 18px;
				font-family: Kano;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 35px;
			}

			.ido-date {
				text-align: right;
				margin-top: 60px;
				font-size: 18px;
				font-family: Kano;
				font-weight: 400;
				color: #8A8A8A;
				line-height: 35px;
			}
		}
	}

	.confirmBtn {
		width: 182px !important;
		height: 41px !important;
		border-radius: 20px !important;
	}

	.usdt-input {
		width: 140px;
		margin-bottom: 20px;

		.MuiInputBase-input {
			text-align: center;
			margin-bottom: 13px;
			font-size: 36px;
			font-family: Seravek;
			font-weight: bold;
			color: #FFFFFF;
		}

		.MuiInputAdornment-root {
			font-size: 20px;
		}
	}
}