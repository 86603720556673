.dashboard-view {
  padding: 30px 86px 0 64px;

  .dashboard-top {
    background: rgba(61, 61, 61, 0.3);
    border-radius: 10px;
    margin-bottom: 45px;

    .top-head {
      padding: 16px 16px 0 20px;
      .head-text {
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: bold;
        color: #FFFFFF;
      }

      .head-btn {
        width: 119px;
        height: 28px;
        background: #E3C990;
        border-radius: 5px;

        font-size: 18px;
        font-family: Kano;
        font-weight: bold;
        color: #000000;
      }
    }

    .top-content {
      padding: 65px 0 57px;
      flex-wrap: wrap;

      .top-content-value-content {
        margin-bottom: 21px;
      }

      .top-content-value {
        margin: 0 9px 0 14px;
        font-size: 36px;
        font-family: Seravek;
        font-weight: bold;
        color: #FFFFFF;
      }

      .top-content-label {
        font-size: 36px;
        font-size: 18px;
        font-family: Kano;
        font-weight: 400;
        color: #8A8A8A;
      }

      .top-content-unit {
        font-size: 24px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #8A8A8A;
      }

      .top-content-img {
        width: 20px;
        height: 20px;
      }

      .top-content-btn {
        display: none;
        width: 119px;
        height: 28px;
        background: #E3C990;
        border-radius: 5px;

        font-size: 18px;
        font-family: Kano;
        font-weight: bold;
        color: #000000;
      }
    }
  }

  .dashboard-bot {
    flex-wrap: wrap;

    .bot-left {
      width: 49%;
      padding: 40px 60px 0px 40px;
      flex-wrap: wrap;
      background: rgba(61, 61, 61, 0.3);
      border-radius: 10px;

      .bot-left-item {
        margin-bottom: 30px;
      }

      .bot-left-label {
        font-size: 18px;
        font-family: Kano;
        font-weight: bold;
        color: #8A8A8A;
        line-height: 35px;
      }

      .bot-left-value {
        font-size: 30px;
        font-family: Seravek;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 35px;
      }
    }

    .bot-right {
      width: 49%;
    }
  }
  .dashboard-item {
    height: 50px;
    background: rgba(61, 61, 61, 0.3);
    border-radius: 10px;
    padding: 20px 25px 20px 15px;

    .item-name {
      font-size: 18px;
      font-family: Kano;
      font-weight: bold;
      color: #8A8A8A;
    }
    .item-amount {
      font-size: 24px;
      font-family: Seravek;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
}

.invite-modal {
	padding: 16px;
	background: #3E3E3E;
	box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.42);
	border-radius: 10px;

	.invite-modal-title {
		font-size: 14px;
		font-family: Arial Black;
		font-weight: 400;
		color: #E3C990;
	}
	.invite-modal-content {
		padding: 30px 0 23px;
		font-size: 14px;
		font-family: Poppins;
		font-weight: 300;
		color: #868686;
	}
}