@media only screen and (max-width: 500px) {
	.dashboard-view {
    padding: 20px 10px;

    .dashboard-top {
      margin-bottom: 30px;
      .top-head {

        .head-text {
          font-size: 16px;
        }
  
        .head-btn {
          display: none;
        }

      }

      .top-content {
        padding: 40px 0;

      .top-content-value-content {
          margin-bottom: 14px;
        }

        .top-content-item {
          width: 100%;
        }

        .top-content-btn { 
          display: flex;
          margin: 46px 0 50px;
        }
      }
    }

    .dashboard-bot {

      .bot-left {
        width: 100%;
        padding: 24px 0 40px 24px;

        .bot-left-item {
          margin-bottom: 24px;
        }

        .bot-left-left {
          width: 100%;
        }

        .bot-left-right {
          width: 100%;
        }
      }

      .bot-right {
        width: 100%;
        margin-top: 40px;
      }

    }

    .dashboard-item {
      padding: 20px;
  
      .item-name {
        font-size: 14px;
      }
      .item-amount {
        font-size: 14px;
      }
    }
  }
}
